import * as React from 'react';
import Link from '../Link/Link';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../assets/olokoo-logo.svg';
import { useState, useEffect } from 'react';

import { slide as Menu } from 'react-burger-menu';
import { Cross as Hamburger } from 'hamburger-react';

export default props => {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const closeMenu = function() {
        setMenuOpen(false);
    };

    const initialHeaderHeight = 140;
    const scrollHeaderHeight = 90;
    const [headerHeight, setHeaderHeight] = useState(initialHeaderHeight);

    useEffect(() => {
        const handler = () => {
            var newHeaderHeight = initialHeaderHeight - window.scrollY;
            if (newHeaderHeight >= scrollHeaderHeight && newHeaderHeight <= initialHeaderHeight) {
                setHeaderHeight(newHeaderHeight);
            } else if (newHeaderHeight >= initialHeaderHeight) {
                setHeaderHeight(initialHeaderHeight);
            } else {
                setHeaderHeight(scrollHeaderHeight);
            }
        };

        window.addEventListener("scroll", handler);
        return () => window.removeEventListener("scroll", handler);
    }, []);

    return (
        <>
            <div className="header-container">
                <div className="header" style={{height: headerHeight + 'px'}}>
                    <div className="header-inner">
                        <Link href="/" className="logo-link" style={{height: headerHeight + 'px', lineHeight: headerHeight + 'px'}}>
                            <Logo className="logo" />
                        </Link>
                        <div className="header-links">
                            {props.links.map((link, index) => (
                                <Link className="header-link" style={{height: headerHeight + 'px', lineHeight: headerHeight + 'px'}} key={ index } href={ link.url } partiallyActive={ link.url !== '/' ? true : false }>
                                    <span className="pretty-underline">{ link.label }</span>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="hamburger-menu-container">
                    <div className="hamburger-button-container" style={{height: headerHeight + 'px', lineHeight: headerHeight + 'px'}}>
                        <Hamburger
                            toggled={ isMenuOpen }
                            toggle={ setMenuOpen }
                            distance="lg"
                            label="Show menu"
                            hideOutline={ true }
                        />
                    </div> 
                    <Menu
                        right
                        isOpen={ isMenuOpen }
                        customCrossIcon={ false }
                        customBurgerIcon={ false }
                        onClose={ closeMenu }
                        width={ '80%' }>
                        <div className="hamburger-menu-link-container" onClick={ closeMenu }>
                            {props.links.map((link, index) => (
                                <Link className="hamburger-menu-link" key={ index } href={ link.url } partiallyActive={ link.url !== '/' ? true : false }>
                                    <span className="pretty-underline">{ link.label }</span>
                                </Link>
                            ))}
                        </div>
                    </Menu>
                </div>
            </div>
        </>
    );
};
