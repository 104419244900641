import React from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import LogoSVGBlack from '../../../assets/olokoo-logo.svg';
import LogoSVGWhite from '../../../assets/olokoo-logo-white.svg';

export const Logo = props => {
  const {
    width,
    style
  } = props;

  const theme = useTheme();

  const useStyles = makeStyles(theme => ({
    logo: {
      width: width,
    },
  }));

  const classes = useStyles();

  return (
    <Box>
      { style === 'black' ?
        <LogoSVGBlack className={classes.logo} />
        :
        <LogoSVGWhite className={classes.logo} />
      }
    </Box>
  );
};
