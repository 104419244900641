import React from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import LogoSVGBlack from '../../../assets/olokoo-logo.svg';
import LogoSVGWhite from '../../../assets/olokoo-logo-white.svg';

import VisibilitySensor from 'react-visibility-sensor';


export const TextVisibilityUnderline = props => {
  const {
    transitionClassName,
    transitionDelay
  } = props;

  return (
    <VisibilitySensor partialVisibility={ true }>
      {({isVisible}) =>
        <Box className={ isVisible ? 'component-visible' : 'component-invisible' }>
          { props.children }
          <style>{`
            .${props.builderBlock.id} .${transitionClassName} { transition-delay: ${transitionDelay} }
          `}</style>
        </Box>
      }
    </VisibilitySensor>
  );
};
