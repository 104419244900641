import React from 'react';

import { useState, useEffect, useCallback, useRef } from 'react';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
import axios from '../../../../lib/axios';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';


import _ from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';


export const Feed = props => {
  const {
    oat,
    uuid
  } = props;

  const isMountedRef = useIsMountedRef();

	const [feed, setFeed] = useState([]);

	const getFeed = useCallback(async () => {
	  try {
      const response = await axios.get('/instagram/feed', {
        params: {
          oat,
          uuid
        }
      })

      if (isMountedRef.current) {
        setFeed(response.data.feed);
      }
	  } catch (err) {
		  console.error(err);
	  }
	}, [isMountedRef]);

	useEffect(() => {
	  getFeed();
	}, [getFeed]);

  const theme = useTheme();

  const useStyles = makeStyles(theme => ({
    post: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
    },
    postWrapper: {
      width: '100%',
      height: '100%'
    },
    modalPostWrapper: {
      position: 'relative'
    },
    listItem: {
      position: 'relative'
    },
    skeleton: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: '-1'
    },
    modalPost: {
      width: '100%',
      height: '100%'
    },
    modalContentWrapper: {
      padding: '20px',
      whiteSpace: 'pre-wrap',
      overflowWrap: 'break-word'
    },
    modal: {
      borderRadius: '0px'
    }
  }));

  const classes = useStyles();

  const postGap = 25;
  const [rowHeight, setRowHeight] = useState(300);
  const [columns, setColumns] = useState(3);

  const breakpoints = {
    xs: useMediaQuery(theme.breakpoints.down('xs')),
    sm: useMediaQuery(theme.breakpoints.down('sm')),
    md: useMediaQuery(theme.breakpoints.down('sm')),
    lg: useMediaQuery(theme.breakpoints.down('lg'))
  };

  const ref = useRef(null);

  useEffect(() => {
    setRowHeight((ref.current.offsetWidth - postGap * (columns + 1)) / columns)
    
    if ( breakpoints.xs ) {
      setColumns(2)
    } else if ( breakpoints.sm ) {
      setColumns(2)
    }
  }, [rowHeight, columns, breakpoints]);

  const PostComponent = ({ post, ...props }) => {
    const [open, setOpen] = React.useState(false);

    const handleOpen = (e) => {
      e.preventDefault();
      setOpen(true);
    };

    const handleClose = (e) => {
      e.preventDefault();
      setOpen(false);
    };
    
    return (
      <>
        <a href={'#' + post.id} onClick={handleOpen}>
          <Skeleton className={classes.skeleton} variant="rect" />
          <LazyLoadImage
            src={post.src}
            effect="opacity"
            className={classes.post}
            wrapperClassName={classes.postWrapper}
          />
          {/* <Image image={ post.src } className={classes.post} lazy /> */}
        </a>
        <Dialog
          key={post.src}
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={'md'}
          scroll={'body'}
          classes={{
            paper: classes.modal
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={6} md={8} className={classes.modalPostWrapper}>
              <Skeleton className={classes.skeleton} variant="rect" />
              <LazyLoadImage
                src={post.src}
                effect="opacity"
                className={classes.post}
                wrapperClassName={classes.postWrapper}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box className={classes.modalContentWrapper}>
                { post.text }
              </Box>
            </Grid>
          </Grid>
        </Dialog>
      </>
    )
  };

  return (
    <Box ref={ref}>
      <ImageList rowHeight={rowHeight} cols={columns} gap={postGap}>
        {feed && feed.length ? ( feed.map((post, index) => (
          <ImageListItem key={post.src} cols={1} key={index} className={classes.listItem}>
            <PostComponent post={post} />
          </ImageListItem>
        )) ) : ( 
          _.times(6, (index) => (
            <ImageListItem cols={1} key={index}>
              <Skeleton sx={{display: 'inline-block'}} variant="rect" width={'100%'} height={'100%'} />
            </ImageListItem>
          ))
        )}
      </ImageList>
    </Box>
  );
};
